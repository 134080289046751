<!-- 成功简介 -->
<template>
    <div>
        <div v-if="isPage == 0">
            <div class="page">
                <div class="page_header">
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item>客户案例</el-breadcrumb-item>
                        <el-breadcrumb-item>简介</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="page_content">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column prop="title" align="center" label="标题"> </el-table-column>
                        <el-table-column prop="intro" align="center" label="简介"> </el-table-column>
                        <el-table-column prop="project_url" align="center" label="链接"> </el-table-column>
                        <el-table-column prop="project_title" align="center" label="链接标题"> </el-table-column>
                        <el-table-column prop="content" align="center" label="内容描述"> </el-table-column>
                        <el-table-column prop="remark" align="center" label="内容备注"> </el-table-column>
                        <el-table-column align="center" label="操作">
                            <template slot-scope="scope">
                                <div class="page_operate">
                                    <button @click="amendClick(scope.row.id)">编辑</button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>

        <amend v-else-if="isPage == 1" @close="onModuleClose" :byData="byAmendId"></amend>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
// import bus from '@/until/eventBus';
// import axios from "axios";

export default {
    components: {
        amend: () => import("./amend.vue"),
    },
    props: {},
    data() {
        return {
            isPage: 0,
            tableData: [{}],

            byAmendId: "",
        };
    },
    mounted() {
        this.getInfo();
        this.$bus.$on("chooseHomePage", res => {
            this.isPage = res;
        });
    },
    created() {},
    methods: {
        // 传值 -- 关闭子组件
        onModuleClose(data, bool) {
            this.isPage = data;
            if (bool) this.getInfo();
        },
        // 修改
        amendClick(id) {
            this.isPage = 1;
            this.byAmendId = id;
        },
        // 获取数据
        getInfo() {
            axios.post("/api/project/introList").then(res => {
                this.tableData = res.data;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_operate {
        button {
            width: 90px;
            height: 36px;
            color: white;
            font-size: 14px;
            border: 0;
            border-radius: 20px;
            background: linear-gradient(to right, #da8cff, #9a55ff);
            cursor: pointer;
        }
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
}
</style>
